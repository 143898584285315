import React, { FC } from 'react'
import emphasizeAmpersand from 'src/helpers/emphasizeAmpersand'

export interface ICaseStudyTechnicalItemProps {
  header: string
  content: string
  wrapContent: boolean
}

const CaseStudyTechnicalItem: FC<ICaseStudyTechnicalItemProps> = ({
  header,
  content,
  wrapContent,
}) => (
  <div className="border-l border-red pl-3">
    <h4 className="font-palanquin font-medium text-xs text-secondaryGray">
      {header}
    </h4>
    <p
      className={`font-palanquinDark font-semibold md:text-lg mt-1 ${
        wrapContent ? '' : 'whitespace-nowrap'
      }`}
    >
      {emphasizeAmpersand(content, 1.5)}
    </p>
  </div>
)

export default CaseStudyTechnicalItem
