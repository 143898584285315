import React, { FC } from 'react'
import Subtitle from 'src/components/ui/Subtitle'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Button from 'src/components/ui/Button'
import styled from 'styled-components'
import tailwind from 'src/helpers/tailwind'

interface IWebinarProps {
  className?: string
  webinarPresentationLink: string
  videoEmbedId: string
}

export const StyledIframe = styled.iframe`
  width: 620px;
  height: 240px;

  @media (min-width: 450px) {
    height: 300px;
  }

  @media (min-width: ${tailwind.theme.screens.sm}) {
    height: 380px;
  }
`

const Webinar: FC<IWebinarProps> = ({
  className,
  videoEmbedId,
  webinarPresentationLink,
}) => {
  const { t } = useTranslation('webinar-section')

  return (
    <section
      id="webinar"
      className={`${className} container py-10 md:py-20`}
      data-name={t('watch out webinar')}
    >
      <Subtitle className="mb-2 md:mb-6 center">
        {t('Watch our webinar')}
      </Subtitle>

      <div className="mt-16 mb-12 text-center">
        <StyledIframe
          className="mx-auto max-w-full"
          src={`https://www.youtube.com/embed/${videoEmbedId}`}
          title="Webinar video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      <div className="text-center">
        <a href={webinarPresentationLink} rel="noreferrer" target="_blank">
          <Button type="submit">{t('Download the presentation')}</Button>
        </a>
      </div>
    </section>
  )
}
Webinar.defaultProps = {
  className: '',
}

export default Webinar
