// i18next-extract-mark-ns-start case-studies

import React, { FC } from 'react'
import CaseStudyTechnicals from 'src/components/sections/caseStudies/CaseStudyTechnicals'
import FormattedHtmlContent from 'src/components/ui/FormattedHtmlContent'
import { directusFileUrl } from 'src/helpers/directus'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from 'src/components/layout'
import FourIconsInRow from 'src/components/sections/four-icons-in-row'
import WebinarSection from 'src/components/sections/webinar'
import { graphql } from 'gatsby'
import ContactSection from 'src/components/sections/ContactSection'
import CaseStudiesTeaser from 'src/components/sections/teaser/CaseStudiesTeaser'

interface ICaseStudyDetailsSectionData {
  id: string
  subtitle: string
  firstPartOfTitle: string
  secondPartOfTitle: string
  content: string
  desktopImage: {
    id: string
  }
}

interface IContributionItemData {
  id: string
  title: string
  content: string
  icon: {
    id: string
  }
}

interface ICaseStudyProps {
  pageContext: {
    platforms: string
    desktopImage: {
      id: string
    }
    translation: {
      subtitle: string
      contentHeader: string
      firstPartOfTitle: string
      secondPartOfTitle: string
      isWebinarDisplayed: boolean
      webinarPresentationLink: string
      webinarVideoLink: string
      excerpt: string
      contribution: string
      sections: ICaseStudyDetailsSectionData[]
      contributionSubtitle: string
      contributionTitleFirstPart: string
      contributionTitleSecondPart: string
      contributionItems: IContributionItemData[]
    }
  }
}

const CaseStudy: FC<ICaseStudyProps> = ({
  pageContext: {
    platforms,
    desktopImage,
    translation: {
      contentHeader,
      firstPartOfTitle,
      secondPartOfTitle,
      isWebinarDisplayed,
      webinarPresentationLink,
      webinarVideoLink,
      excerpt,
      contribution,
      sections,
      contributionSubtitle,
      contributionTitleFirstPart,
      contributionTitleSecondPart,
      contributionItems,
    },
  },
}) => {
  const { t } = useTranslation('case-studies')
  const title = `${firstPartOfTitle} ${firstPartOfTitle}`

  return (
    <Layout title={title} metas={[]}>
      <CaseStudiesTeaser
        className="mt-16 md:mt-28"
        translationContent={{
          subtitle: contentHeader,
          firstPartOfTitle,
          secondPartOfTitle,
          sectionName: contentHeader,
          image: {
            url: directusFileUrl(desktopImage.id),
            alt: `title`,
          },
        }}
        hideImageOnMobile
      >
        <FormattedHtmlContent
          dangerouslySetInnerHTML={{ __html: excerpt }}
          className="font-palanquin font-semibold mt-10"
        />
        <CaseStudyTechnicals
          items={[
            {
              id: 'platform',
              header: t('platform'),
              content: platforms,
              wrapContent: false,
            },
            {
              id: 'contribution',
              header: t('contribution'),
              content: contribution,
              wrapContent: true,
            },
          ]}
          className="mt-8"
        />
      </CaseStudiesTeaser>
      {sections.map((section, index) => {
        const isReversed = index % 2 === 0
        return (
          <CaseStudiesTeaser
            key={section.id}
            translationContent={{
              firstPartOfTitle: section.firstPartOfTitle,
              secondPartOfTitle: section.secondPartOfTitle,
              subtitle: section.subtitle,
              sectionName: section.subtitle,
              image: {
                url: directusFileUrl(section.desktopImage.id),
                alt: `${section.firstPartOfTitle} ${section.secondPartOfTitle}`,
              },
            }}
            isReversed={isReversed}
            isImageSnapped={!isReversed}
          >
            <FormattedHtmlContent
              className={`font-semibold mt-12 ${
                isReversed ? 'md:ml-10' : 'md:mr-10'
              }`}
              dangerouslySetInnerHTML={{ __html: section.content }}
            />
          </CaseStudiesTeaser>
        )
      })}
      <FourIconsInRow
        translationContent={{
          sectionName: contributionSubtitle,
          subtitle: contributionSubtitle,
          firstPartOfTitle: contributionTitleFirstPart,
          secondPartOfTitle: contributionTitleSecondPart,
          icons: contributionItems.map((item) => ({
            id: item.id,
            image: {
              url: directusFileUrl(item.icon.id),
              alt: item.title.replace(/\n/g, ' '),
            },
            title: item.title,
            content: item.content,
          })),
        }}
        smallIconTitles
      />
      {isWebinarDisplayed && (
        <WebinarSection
          videoEmbedId={webinarVideoLink}
          webinarPresentationLink={webinarPresentationLink}
        />
      )}
      <ContactSection />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CaseStudy
