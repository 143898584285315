import React, { FC } from 'react'
import CaseStudyTechnicalItem, {
  ICaseStudyTechnicalItemProps,
} from 'src/components/sections/caseStudies/CaseStudyTechnicalItem'
import styled from 'styled-components'
import tw from 'twin.macro'

interface ICaseStudyTechnicalsProps {
  items: ICaseStudyTechnicalItemData[]
  className?: string
}

interface ICaseStudyTechnicalItemData extends ICaseStudyTechnicalItemProps {
  id: string
}

const GapBox = styled.div`
  & > *:not(:last-child) {
    ${tw`mr-8`}
  }
`

const CaseStudyTechnicals: FC<ICaseStudyTechnicalsProps> = ({
  items,
  className,
}) => (
  <GapBox className={`flex items-start ${className}`}>
    {items.map(({ id, header, content, wrapContent }) => (
      <CaseStudyTechnicalItem
        key={id}
        header={header}
        content={content}
        wrapContent={wrapContent}
      />
    ))}
  </GapBox>
)

CaseStudyTechnicals.defaultProps = {
  className: '',
}

export default CaseStudyTechnicals
